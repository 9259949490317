import { getHeightOfHidden } from '../helpers/getHeightOfHidden';
import { fontsLoaded } from '../helpers/fontsLoaded';

export class DesktopNavigation {
    navContainer: HTMLElement;
    cssShort: string;
    cssTall: string;
    cssOpen: string;
    submenuSelector: string;
    parentNodes: Array<HTMLLIElement>;
    touchedItem: HTMLLIElement;
    isShort: boolean;

    constructor(container: HTMLElement) {
        const typefaceExample = "14px Roboto";

        this.navContainer = container;
        this.cssShort = 'collapsed';
        this.cssTall = 'expanded';
        this.cssOpen = 'open';
        this.submenuSelector = 'data-nav-desktop-submenu';
        this.parentNodes = [].slice.call(this.navContainer.querySelectorAll('[data-nav-desktop-parent]'));
        this.isShort = false;
        this.init();
        this.setSubmenuHeights();

        fontsLoaded(typefaceExample, this.setSubmenuHeights.bind(this));
    }

    init() {
        // Touch handling: collapse open submenu when touched outside nav
        document.addEventListener('touchstart', (event) => {
            if (this.touchedItem && !(this.touchedItem == event.target || this.touchedItem.contains(event.target as HTMLElement))) {
                this.touchedItem.classList.remove(this.cssOpen);
                this.touchedItem = null;
            }
        });

        // Touch handling: open submenu and prevent clicking through to landing page on first tap
        this.parentNodes.forEach(parent => {
            parent.addEventListener('touchstart', (event) => {
                if (this.touchedItem && this.touchedItem == parent) {
                    this.touchedItem = null;
                }
                else {
                    if (this.touchedItem && this.touchedItem !== parent) {
                        this.touchedItem.classList.remove(this.cssOpen);
                    }
                    this.touchedItem = parent;
                    event.preventDefault();
                    parent.classList.add(this.cssOpen);
                }
            });
            parent.addEventListener('click', (event) => {
                if (this.touchedItem == parent) {
                    event.preventDefault();
                }
            });
        });

        // Keyboard handling: open submenus when focused on a child item
        this.parentNodes.forEach(parent => {
            const childLinks = [].slice.call(parent.querySelectorAll('a'));
            childLinks.forEach(link => {
                link.addEventListener('focus', () => {
                    if (!parent.classList.contains(this.cssOpen)) {
                        parent.classList.add(this.cssOpen);
                    }
                    if (this.isShort) {
                        this.expand();
                    }
                });
                link.addEventListener('blur', () => {
                    if (!parent.contains(document.activeElement)) {
                        parent.classList.remove(this.cssOpen);
                    }
                })
            })
        });
    }

    setSubmenuHeights() {
        // Set resting height of submenus so they can be smoothly animated open/closed
        this.parentNodes.forEach(parent => {
            const submenu = parent.querySelector(`[${this.submenuSelector}]`) as HTMLElement;
            if (submenu) {
                submenu.style.height = '';
                const submenuHeight = getHeightOfHidden(submenu);
                submenu.style.height = `${submenuHeight}px`;
            }
        });
    }

    shrink() {
        this.navContainer.classList.remove(this.cssTall);
        this.isShort = true;
        this.navContainer.classList.add(this.cssShort);
    }

    expand() {
        this.navContainer.classList.remove(this.cssShort);
        this.isShort = false;
        this.navContainer.classList.add(this.cssTall);
    }
}