import { Store, Action, AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { Promise } from 'es6-promise';
import { Dispatch } from 'react-redux';
import 'whatwg-fetch';

export interface IFetchAction extends Action {
    fetch: Request
    meta?: any
}

export interface FetchPendingAction {
    type: string;
    fetch: Promise<void>;
}

export enum FetchStatus {
    Pending = '/pending',
    Complete = '/complete',
    Failed = '/failed'
}

export const fetchMiddleware: any = ({ dispatch }: MiddlewareAPI<any>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {

    // Check if this is a fetch action
    if (!action.fetch) {
        return next(action);
    }

    // Set default JSON header
    action.fetch.headers.set('Content-Type', 'application/json; charset=utf-8')


    // Create the request
    const request = fetch(action.fetch)
        // Get data on ok, reject on not ok
        .then(res => {
            // If we get a redirect back from an api then redirect the user
            if (res.redirected) {
                location.href = res.url;
            }
            console.log(res);
            return res.ok

                // Only look at json on 200
                ? (res.status === 200 ? res.json() : null)
                : res.json().then(json => Promise.reject(json))
        }
            // Dispatch the complete action with data on success
        ).then(data => {
            dispatch({
                type: action.type + FetchStatus.Complete,
                data,
                meta: action.meta
            });
            // Dispatch error on failure
        }).catch(err => {
            dispatch({
                type: action.type + FetchStatus.Failed,
                err,
                meta: action.meta
            });
            throw err;
        })

    // Continue to next middleware
    return next({
        type: action.type + FetchStatus.Pending,
        fetch: request,
        meta: action.meta
    })
}