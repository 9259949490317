export function fontsLoaded(typefaceExample: string, callback: () => any) {
    // try to wait for fonts to be ready and set the submenu heights again since the height changes
    // this design uses an enormous amount of external fonts...
    try {
        let fontsLoaded = (document as any).fonts.check(typefaceExample);
        if (!fontsLoaded) {
            const fontCheckTick = setInterval(() => {
                fontsLoaded = (document as any).fonts.check(typefaceExample);
                if (fontsLoaded) {
                    callback();
                    clearInterval(fontCheckTick);
                }
            }, 20);
        }
    }
    catch (err) { /* just don't kill all js in IE */ }
}