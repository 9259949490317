import { AnyAction } from "redux";
import { IFetchAction, FetchStatus, FetchPendingAction } from "../middleware/fetch-middleware";

export enum ContentApiActions {
    GetContentProps = 'contentApiActions/getContentProps',
    GetFolderProps = 'contentApiActions/getFolderProps'
}

export interface ContentApiState<TProps> {
    props: TProps
}

const DefaultContentApiState = {
    props: {}
}

export const contentApiReducerWithDefault = (defaultState: ContentApiState<any>) => (state = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ContentApiActions.GetContentProps + FetchStatus.Complete:
            return { ...state, props: JSON.parse(action.data) }
        default:
            return state;
    }
}

export const contentApiReducer = contentApiReducerWithDefault(DefaultContentApiState);
const BaseUrl = '/api/v1/content';
export const getFolderItems = function(contentFolderId: string): IFetchAction {
    const requestUrl = `${BaseUrl}/folder?contentId=${contentFolderId}`;
    return {
        type: ContentApiActions.GetContentProps,
        fetch: new Request(requestUrl)
    }
}

export const getContentProps = function (contentId: string, maxNestedResults?: number, nestedResultsSkipCount?: number): IFetchAction {
    let requestUrl = `${BaseUrl}/properties?contentId=${contentId}&includeGlobalSettingClientProperties=true`;
    if (maxNestedResults !== undefined && nestedResultsSkipCount !== undefined) {
        requestUrl = `${requestUrl}&maxNestedResults=${maxNestedResults}&nestedResultsSkipCount=${nestedResultsSkipCount}`;
    }
    return {
        type: ContentApiActions.GetContentProps,
        fetch: new Request(requestUrl) 
    }
}