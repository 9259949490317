import { getHeightOfHidden } from '../helpers/getHeightOfHidden';
import { fontsLoaded } from '../helpers/fontsLoaded';
import { bodyLock, bodyUnlock } from '../helpers/bodyLock';

interface ISubmenu {
    trigger: HTMLButtonElement;
    panel: HTMLElement;
    childLinks: Array<HTMLAnchorElement>;
    isOpen: boolean;
}

export class MobileNavigation {
    navContainer: HTMLElement;
    panel: HTMLElement;
    trigger: HTMLButtonElement;
    isOpen: boolean;
    submenus: Array<ISubmenu>;
    cssTall: string;
    cssShort: string;
    isShort: boolean;
    bodyStyles: string;

    constructor(container: HTMLElement) {
        const panelId = 'Nav_panel';
        const submenuSelector = 'data-nav-mobile-group';
        const submenuIdPrefix = 'Submenu_';
        const typefaceExample = "14px Roboto";
        this.cssTall = 'expanded';
        this.cssShort = 'collapsed';

        this.navContainer = container;
        this.panel = this.navContainer.querySelector(`#${panelId}`) as HTMLElement;
        this.trigger = this.navContainer.querySelector(`[aria-controls="${panelId}"]`) as HTMLButtonElement;
        this.submenus = [];

        const groups = [].slice.call(this.navContainer.querySelectorAll(`[${submenuSelector}]`)) as HTMLElement[];
        groups.forEach(group => {
            const groupIndex = group.getAttribute(submenuSelector);
            const groupPanel = group.querySelector(`#${submenuIdPrefix}${groupIndex}`) as HTMLElement;
            const groupTrigger = group.querySelector(`[aria-controls="${submenuIdPrefix}${groupIndex}"]`) as HTMLButtonElement;

            const submenu: ISubmenu = {
                panel: groupPanel,
                trigger: groupTrigger,
                childLinks: [].slice.call(groupPanel.querySelectorAll('a')) as Array<HTMLAnchorElement>,
                isOpen: false
            }
            this.submenus.push(submenu);
        });

        this.init();

        fontsLoaded(typefaceExample, this.setSubmenuHeights.bind(this));
    }

    init() {
        this.trigger.addEventListener('click', this.togglePanel.bind(this));
        this.setSubmenuHeights();

        this.submenus.forEach(submenu => {
            this.disableLinks(submenu.childLinks);
            submenu.trigger.addEventListener('click', () => {
                if (submenu.isOpen) {
                    submenu.trigger.setAttribute('aria-expanded', 'false');
                    submenu.panel.setAttribute('aria-hidden', 'true');
                    this.disableLinks(submenu.childLinks);
                }
                else {
                    submenu.trigger.setAttribute('aria-expanded', 'true');
                    submenu.panel.setAttribute('aria-hidden', 'false');
                    this.enableLinks(submenu.childLinks);
                }
                submenu.isOpen = !submenu.isOpen;
            });
        })
    }

    setSubmenuHeights() {
        this.submenus.forEach(submenu => {
            const submenuHeight = getHeightOfHidden(submenu.panel, this.panel);
            submenu.panel.style.height = `${submenuHeight}px`;
        })
    }

    togglePanel() {
        if (this.isOpen) this.close();
        else this.open();
    }

    open() {
        this.panel.setAttribute('aria-hidden', 'false');
        this.trigger.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
        this.bodyStyles = bodyLock();
    }

    close() {
        this.panel.setAttribute('aria-hidden', 'true');
        this.trigger.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
        bodyUnlock(this.bodyStyles);
    }

    shrink() {
        this.navContainer.classList.remove(this.cssTall);
        this.isShort = true;
        this.navContainer.classList.add(this.cssShort);
    }

    expand() {
        this.navContainer.classList.remove(this.cssShort);
        this.isShort = false;
        this.navContainer.classList.add(this.cssTall);
    }

    enableLinks(links: Array<HTMLAnchorElement>) {
        links.forEach(link => {
            link.removeAttribute('tabIndex');
        })
    }

    disableLinks(links: Array<HTMLAnchorElement>) {
        links.forEach(link => {
            link.tabIndex = -1;
        })
    }
}