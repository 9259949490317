import { DesktopNavigation } from '../classes/desktopNavigation';
import { MobileNavigation } from '../classes/mobileNavigation';
const throttle = require('lodash.throttle');
const debounce = require('lodash.debounce');

class Navigation {
    container: HTMLElement;
    desktopNav: DesktopNavigation;
    mobileNav: MobileNavigation;
    lastPos: number;
    lastDelta: number;
    isShort: boolean;
    buffer: number;
    desktopTall: number;
    desktopShort: number;
    mobileTall: number;
    mobileShort: number;

    constructor(container: HTMLElement) {
        this.container = container;
        const desktopNavEl = container.querySelector('[data-nav-desktop]') as HTMLElement;
        const mobileNavEl = container.querySelector('[data-nav-mobile]') as HTMLElement;

        this.isShort = false;
        this.lastPos = window.pageYOffset;
        this.lastDelta = 0;
        this.buffer = 20;

        if (desktopNavEl) {
            this.desktopNav = new DesktopNavigation(desktopNavEl);
            this.desktopShort = parseInt(desktopNavEl.getAttribute('data-nav-short'));
            this.desktopTall = parseInt(desktopNavEl.getAttribute('data-nav-tall'));
        }

        if (mobileNavEl) {
            this.mobileNav = new MobileNavigation(mobileNavEl);
            this.mobileShort = parseInt(mobileNavEl.getAttribute('data-nav-short'));
            this.mobileTall = parseInt(mobileNavEl.getAttribute('data-nav-tall'));
        }

        if (desktopNavEl && mobileNavEl) {
            document.addEventListener('scroll', throttle(() => {
                this.checkPosition();
                this.setClearance();
            }, 10));
            window.addEventListener('resize', debounce(() => {
                this.desktopNav.setSubmenuHeights();
                this.checkPosition();
                this.setClearance();
                if (this.mobileNav.isOpen && this.desktopNavActive()) {
                    this.mobileNav.close();
                }
            }, 50));

            this.initHeightAdjustments();
            this.setClearance();
        }
    }

    setClearance() {
        // Heights need to be hardcoded in the view. This is because the "skip to first block" functionality
        // requires knowing where the first block is, but when the page scrolls down, the header height changes.
        // Since the height change is animated, we can't get the short height programmatically and need
        // to pass it here and adjust the "clearance" based on the active nav's shorter height (since the page scrolls down)

        const desktopHeight = this.desktopNav.isShort ? this.desktopShort : this.desktopTall;
        const mobileHeight = this.mobileNav.isShort ? this.mobileShort : this.mobileTall;
        const activeHeight = this.desktopNavActive() ? desktopHeight : mobileHeight;
        document.body.style.setProperty('--nav-clearance', `${activeHeight}px`);
    }

    initHeightAdjustments() {
        // If the user has changed their default font size, the pixel heights hardcoded in the view will be wrong
        // We can get the correct font size multiplier and adjust here so that the offset is corrected
        const currentHeight = this.container.offsetHeight;
        const isDesktop = this.desktopNavActive();
        let multiplier = 1;
        if (isDesktop) {
            if (this.isShort) multiplier = this.desktopShort / currentHeight;
            else multiplier = this.desktopTall / currentHeight;
        }
        else {
            if (this.isShort) multiplier = this.mobileShort / currentHeight;
            else multiplier = this.mobileTall / currentHeight;
        }

        this.desktopShort *= multiplier;
        this.desktopTall *= multiplier;
        this.mobileShort *= multiplier;
        this.mobileTall *= multiplier;
    }

    desktopNavActive(): boolean {
        // Return whether it's the desktop view. Height will be 0 if it's hidden.
        return this.desktopNav.navContainer.offsetHeight > 0;
    }

    checkPosition() {
        // Shrink and Expand: shrink when there's positive scroll velocity, otherwise expand
        const curPos = window.pageYOffset;
        const delta = curPos - this.lastPos;
        this.lastPos = curPos;

        if (!this.isShort) {
            if (delta > 0 && this.lastDelta > 0 && curPos >= this.buffer) {
                this.isShort = true;
                this.desktopNav.shrink();
                this.mobileNav.shrink();
            }
        }
        else if ((delta < 0 && this.lastDelta < 0) || curPos < this.buffer) {
            this.isShort = false;
            this.desktopNav.expand();
            this.mobileNav.expand();
        }

        this.lastDelta = delta;
    }
}

// Navigation Setup
let nav: Navigation;
const headerContainer = document.querySelector('header[role="banner"]') as HTMLElement;
if (headerContainer) {
    nav = new Navigation(headerContainer);
}

export { nav };